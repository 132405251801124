$pink: #f17d5e;

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-variant: small-caps;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f4f4f4;
}

#left-pane {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;
	width: 55vw;

	#header {
		padding: 30px;
		position: relative;
		width: 100%;
		text-align: center;
		box-sizing: border-box;

		#logo {
			height: 60px;
		}

		select {
			position: absolute;
			top: 50%;
			right: 70px;
			transform: translateY(-50%);
			font-size: 0.8em;
			font-weight: 900;
			appearance: none;
			box-shadow: none;
			outline: none;
			background: transparent;
			background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
			background-repeat: no-repeat;
			background-position-x: 21px;
			background-position-y: 0px;
			border: none;
			padding: 5px;
			width: 44px;
			cursor: pointer;
		}
	}

	#content {
		width: 80%;
	}
	
	#footer {
		padding: 15px;
		text-align: center;

		a {
			text-decoration: none;
			color: black;
			font-size: 1em;
		}

		a + a:before {
			content: '\00B7';
			margin: 0 10px;
			pointer-events: none;
		}
	}
}

#markdown-root {
	#left-pane #header select {
		display: none;
	}
}

#right-pane {
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 45vw;
	gap: 20px;
	align-items: center;

	.column {
		flex: 0 0 35%;

		&:first-child {
			animation: slide 30s linear infinite alternate;
		}

		&:last-child {
			animation: slide 30s linear infinite alternate-reverse;
		}

		.mockup {
			border-radius: 20px;
			width: 100%;
			margin: 10px 0;
		}
	}
}

@keyframes slide {
	from {
		transform: translateY(-10%);
	}

	to {
		transform: translateY(10%);
	}
}

.homepage {
	display: flex;
	flex-direction: column;
	align-items: center;

	span {
		display: block;
		font-weight: 900;
		font-size: 3em;
		text-transform: uppercase;
	}

	ol {
		width: 70%;
		align-self: flex-start;
		font-size: 1.4em;
	}

	#download-links {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;

		img {
			height: 70px;
			margin: 9.75px;
		}
	}

	#mockups {
		display: none;
	}
}

.contact-us {
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	input, textarea, button {
		padding: 10px;
		border: 1px solid black;
		border-radius: 10px;
		
		&:focus {
			outline-color: $pink;
		}
	}
	
	textarea {
		min-width: 100% !important;
		max-width: 100% !important;
		box-sizing: border-box;
		height: 50vh;
	}

	button {
		background-color: black;
		color: white;
		border: none;
		font-weight: 900;
		text-transform: uppercase;
		font-size: 1em;
		cursor: pointer;
	}
}

.markdown {
	font-variant: none;
	
	table {
		border-collapse: collapse;
	}

	table, th, td {
		border: 1px black solid;
	}

	th, td {
		padding: 3px;
	}
	
	td:last-child {
		text-align: center;
	}
}

.spinner {
	border: 2px solid transparent;
	border-top: 2px solid white;
	border-radius: 50%;
	width: 1em;
	height: 1em;
	animation: spin 1s linear infinite;
	text-align: center;
	display: inline-flex;
	box-sizing: border-box;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 1000px) {
	#left-pane {
		#header {
			padding: 20px;

			#logo {
				height: 45px;
			}
			
			select {
				right: 10%;
			}
		}

		#footer {
			padding: 10px;

			a {
				font-size: 0.8em;
			}

			a+a:before {
				margin: 0 5px;
			}
		}
	}

	#right-pane {
		gap: 10px;

		.column {
			flex-basis: 55%;

			&:first-child {
				animation-duration: 15s;
			}

			&:last-child {
				display: none;
			}

			.mockup {
				margin: 5px 0;
			}
		}
	}

	@keyframes slide {
		from {
			transform: translateY(-5%);
		}

		to {
			transform: translateY(5%);
		}
	}

	.homepage {
		span {
			font-size: 2em;
		}

		ol {
			font-size: 1em;
		}

		#download-links  img {
			height: 50px;
			margin: 6.25px;
		}
	}

	.markdown {
		font-size: 0.8em;

		th, td {
			padding: 2px;
		}
	}
}

@media (max-width: 600px) {
	#left-pane {
		width: 100vw;
	}

	#right-pane {
		display: none;
	}

	#homepage-root {
		#left-pane #footer a {
			text-shadow: 0 0 5px black;
			color: white;
		}
	
		#right-pane {
			display: block;
			z-index: -100;
	
			.column {
				&:first-child {
					display: none;
				}
	
				.mockup {
					height: 50%;
					width: initial !important;
					left: 50%;
					position: fixed;
				}
	
				.mockup:nth-child(1) {
					bottom: -10%;
					transform: translateX(-100%) rotate(-20deg);
				}
	
				.mockup:nth-child(2) {
					bottom: -5%;
					transform: translateX(-50%);
				}
	
				img:nth-child(3) {
					bottom: -10%;
					transform: rotate(20deg);
				}
			}
		}
	
		.homepage {
			flex-grow: 1;
			padding: 5% 0;
		}
	}
}
